@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import "./codemirror/codemirror.css";
@import "../node_modules/codemirror/theme/material-darker.css";
@import "../node_modules/codemirror/addon/scroll/simplescrollbars.css";

:root {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  --dark: #111;
  --light: #f0f0f0;
}

a {
  text-decoration: none;
  color: var(--light);
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #111;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.5rem;
  padding: 0rem;
  display: block;
}

h3 {
  margin-right: 0;
}

td,
th {
  height: 1rem;
}

ul {
  padding: 0 0.5rem;
}

li {
  list-style-type: none;
}

button {
  border: none;
  width: 100%;
}

input:disabled {
  border-color: rgba(0, 0, 0, 0.5);
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links a {
  color: silver;
  margin: 0 0.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
}

.nav-links a:hover {
  color: var(--light);
  border-color: var(--light) !important;
}

.nav-links a span {
  margin: 0 0.25rem;
}

.container {
  display: grid;
  padding: 3rem 0 0 0;
  height: 100vh;
  grid-template-columns: 250px minmax(0, 4fr) minmax(0, 2fr);
  grid-template-areas: "menu main result";
}

.menu {
  grid-area: "menu";
  background-color: #111;
  padding: 0.5rem;
  box-shadow: 2px -2px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result {
  grid-area: "result";
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.main {
  grid-area: "main";
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.samples-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  overflow: hidden;
}

.samples-wrapper > * {
  position: relative;
  overflow: hidden;
}

.output-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.counter {
  justify-self: flex-end;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter.all-match {
  color: #93eea8;
}

.counter.no-match {
  color: #f78c6c;
}

/* MODAL */

.modal {
  background-color: rgba(20, 20, 20, 0.664);
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  top: 0;
  left: 0;
}

.modal-container {
  background-color: #111;
  padding: 1rem;
  border: 1px solid siver;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 50vw;
}

.modal-header {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #444;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-header h3 {
  margin: 0 0 0 0.5rem;
}

.modal-header svg {
  color: silver;
}

.modal-header svg:hover {
  color: #fff;
  cursor: pointer;
}

.grid-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-content input {
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
}

.modal-footer {
  margin-top: 1rem;
}

.notification {
  display: flex;
  align-items: center;
  background-color: salmon;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.notification span {
  margin-left: 0.5rem;
}

.error {
  background-color: salmon;
  color: #000;
}

.warn {
  background-color: palegoldenrod;
  color: #000;
}

.row div {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-top: 0.5rem;
  margin: 0 0.5rem;
}

.grid-headers div {
  display: flex;
  align-items: center;
}

.pattern-grid {
  background-color: #111;
}

.pattern-grid .grid-headers {
  font-weight: bold;
  color: #fff;
  background-color: #555;
  display: grid;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  padding: 0.5rem;
  border: 1px solid #444;
  height: 3rem;
  width: 100%;
}

.pattern-grid .row {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  border: 1px solid #666;
  border-top: none;
}

.btn.delete {
  background-color: salmon;
  width: 2rem;
  height: 2rem;
}

.btn.edit {
  background-color: #89ddff;
  width: 2rem;
  height: 2rem;
}

.btn.success {
  background-color: #93eea8;
}

.btn.secondary {
  background-color: transparent;
  color: var(--light);
  border: 1px solid silver;
}

.btn.secondary:active {
  background-color: transparent;
  color: #89ddff;
  border: 1px solid #89ddff;
}

.btn > span {
  font-size: 1rem;
}

/* DONT TOUCH */

.pattern-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  position: relative;
}

.input-menu {
  display: flex;
  align-items: center;
}

.input-menu svg {
  color: silver;
  margin-left: 1rem;
}

.input-menu svg:hover {
  color: #fff;
  cursor: pointer;
}

.input-menu svg:active {
  color: #89ddff;
  cursor: pointer;
}

.output-wrapper {
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.output-wrapper > * {
  position: relative;
  overflow: hidden;
}

.collections-wrapper {
  padding: 1rem 0.5rem;
}

.collections-wrapper div a:hover {
  color: var(--light) !important;
}

.set-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  background-color: #333;
  border: 1px solid #666;
}

.react-codemirror2 {
  max-width: 100%;
  height: 100%;
}

.CodeMirror {
  border: 1px solid #222;
}

.CodeMirror-focused {
  border: 1px solid silver;
}

.btn {
  background-color: #89ddff;
  opacity: 0.9;
  padding: 0.5rem;
  border-radius: 5px;
  position: relative;
  color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

button.btn :nth-child(1) {
  margin-right: 0.5rem;
}

.btn:hover {
  opacity: 1;
  cursor: pointer;
}

/* MULTI SELECT */

/* MEDIA QUERIES */

@media (max-width: 700px) {
  .navbar h1 {
    font-size: 1.5rem;
  }
  .navbar span.beta {
    display: none;
  }
  .nav-links span {
    display: none;
  }
  .nav-links svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .main {
    padding-bottom: 0;
  }
  .menu {
    display: none;
  }
  .container {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main" "result";
  }
}
